import { lazy } from "react"

// public path
const Login = lazy(() => import('../pages/auth/login'));
const Register = lazy(() => import('../pages/auth/register'));
const Home = lazy(() => import('../pages/marketplace/home'));
const Product = lazy(() => import('../pages/marketplace/product'));

// protected path
const Sell = lazy(() => import('../pages/marketplace/sell'));
const Profile = lazy(() => import('../pages/profile'));
const Chat = lazy(() => import('../pages/chat/Chat'));

const authProtectedRoutes = [
  { path: "/sell", component: Sell },
  { path: "/profile", component: Profile },
  { path: "/chat", component: Chat },
]

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/product/:id", component: Product },
]

export { authProtectedRoutes, publicRoutes }
