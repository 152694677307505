import * as actions from '../actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'isAuthenticated',
    storage: storage,
};

const authReducer = (state = { isAuthenticated: false, obj: {} }, action) => {
    switch (action.type) {
        case actions.LOGIN:
            return { ...state, isAuthenticated: true };

        case actions.LOGOUT:
            localStorage.removeItem("token");
            return { ...state, isAuthenticated: false };

        case actions.auth:
            console.log("auth is call in reducer", action.payload, "type", action.type)
            return { ...state, obj: action.payload };

        default:
            return { ...state };
    }
};

export default persistReducer(persistConfig, authReducer)