// import React, { lazy, Suspense } from 'react';
// import { Provider } from 'react-redux';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,

// } from "react-router-dom";
// import ScrollToTop from 'react-scroll-to-top';
// import Loader from './utils/loader';

// const Login = lazy(() => import('./pages/auth/login'));
// const Register = lazy(() => import('./pages/auth/register'));
// const Home = lazy(() => import('./pages/marketplace/home'));
// const Product = lazy(() => import('./pages/marketplace/product'));
// const Sell = lazy(() => import('./pages/marketplace/sell'));
// const Profile = lazy(() => import('./pages/profile'));
// const Chat = lazy(() => import('./pages/chat/Chat'));

// function App() {
//   return (
//     <>
//       <Provider store={store}>
//         <ScrollToTop smooth width="20" color='#0091FF' style={{ borderRadius: "50%", lineHeight: "10px" }} />
//         <Router>
//           {/* <ScrollTop /> */}
//           <Routes>
//             <Route exact path="/" element={<Suspense fallback={<Loader />}><Home /></Suspense>}></Route>
//             <Route exact path="/product/:id" element={<Suspense fallback={<Loader />}><Product /></Suspense>}></Route>
//             <Route exact path="/login" element={<Suspense fallback={<Loader />}><Login /></Suspense>}></Route>
//             <Route exact path="/register" element={<Suspense fallback={<Loader />}><Register /></Suspense>}></Route>
//             <Route exact path="/profile" element={<Suspense fallback={<Loader />}><Profile /></Suspense>}></Route>
//             <Route exact path="/sell" element={<Suspense fallback={<Loader />}><Sell /></Suspense>}></Route>
//             <Route exact path="/chat" element={<Suspense fallback={<Loader />}><Chat /></Suspense>}></Route>
//           </Routes>
//         </Router>
//       </Provider>
//     </>
//   )
// }

// export default App;

import React from "react";
import { Provider } from "react-redux";
import { store } from './redux/store';

import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// Import Router all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";
import './App.css'

const App = props => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <ScrollToTop smooth width="20" color='#0091FF' style={{ borderRadius: "50%", lineHeight: "10px" }} />
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </Provider>
    </React.Fragment>
  );
};


export default App;