import { notification } from "antd";

//Action Types
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const auth = "AUTH";
export const STARTLOADER = "STARTLOADER";
export const STOPLOADER = "STOPLOADER";
export const DrawerOpen = "DrawerOpen";
export const DrawerClose= "DrawerClose";


//Action Creator
export const authLogin = () => ({
    type: LOGIN
});
export const DrawerO = () => ({
    type: DrawerOpen
});
export const DrawerC = () => ({
    type: DrawerClose
});

export const authLogout = () => ({
    type: LOGOUT,
});

export const startloader = () => ({
    type: STARTLOADER
});

export const stoploader = () => ({
    type: STOPLOADER
});

export const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  


export const saveObj = () => dispatch => {
    return new Promise((resolve, reject) => {

        try{
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
    
            fetch("https://api.clearpricing.health/landingpageData", requestOptions)
                .then(response => response.json())
                .then(result => {
                    dispatch({
                        type: auth,
                        payload: result
                    });
                    resolve({ obj: result });
                })
                .catch(error => {
                    reject(error)
                });   
        }
        catch(error){
            reject(error)

        }
        
    })
};

