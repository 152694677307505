import React, { Suspense } from "react"
import { Route, Redirect } from "react-router-dom"
import { openNotificationWithIcon } from "../redux/actions";
import Loader from "../utils/loader"

const Authmiddleware = ({
  component: Component,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("token")) {
        openNotificationWithIcon("warning", 'Opps!', "Please Login First");
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
          <Suspense fallback={<Loader />}><Component {...props} /></Suspense>
      )
    }}
  />
)

export default Authmiddleware;
