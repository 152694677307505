import authReducer from './authReducer';
import utilsReducer from './utilsReducer'
import DrawerHandle from './DrawerHandle';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    login: authReducer,
    loader: utilsReducer,
    drawer:DrawerHandle
});

export default rootReducer;
