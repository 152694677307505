import * as actions from '../actions';

const utilsReducer = (state = { loader: false }, action) => {
    switch (action.type) {
        case actions.STARTLOADER:
            return { ...state, loader: true };

        case actions.STOPLOADER:
            return { ...state, loader: false };

        default:
            return { ...state };
    }
};

export default utilsReducer