import * as actions from '../actions';

const DrawerHandle = (state = { Drawer: false }, action) => {
    switch (action.type) {
        case actions.DrawerOpen:
            return { ...state, Drawer: true };
        case actions.DrawerClose:
            return { ...state, Drawer: false };
        default:
            return { ...state };
    }
};
export default DrawerHandle